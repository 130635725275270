<script lang="ts" setup>
  import type { RouteLocationRaw } from 'vue-router';
  import VButton from '~/components/ui/VButton.vue';

  interface IProps {
    title?: string;
    link?: {
      name: string;
      route: RouteLocationRaw;
      mobileName?: string;
    };
    section?: boolean;
    removePadding?: boolean;
    offset?: boolean;
    loading?: boolean;
    lazy?: boolean;
    hideTitleMobile?: boolean;
    hasNextPage?: boolean;
    useLowPriorityTitle?: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    lazy: true,
  });
</script>

<template>
  <component
    :is="props.section ? 'section' : 'div'"
    class="wrapper"
    :class="{ offset: props.offset, 'hide-title': props.hideTitleMobile }"
  >
    <template v-if="$slots.title || $slots.subtitle || props.title">
      <div class="wrapper__header">
        <component
          :is="useLowPriorityTitle ? 'h2' : 'h1'"
          v-if="props.title || $slots.title"
          class="wrapper__title"
          :class="{ subtitle: $slots.subtitle }"
        >
          <slot name="title">
            {{ props.title }}
          </slot>
        </component>
        <slot name="header-suffix" />
        <v-button
          v-if="props.link"
          link
          class="wrapper__link"
          appearance="text"
          icon="arrow-right"
          :to="props.link.route"
          :data-link="props.link.name"
          :data-mobile-link="props.link.mobileName || 'Все'"
          :aria-label="props.link.name"
        >
        </v-button>
      </div>
      <div v-if="!props.removePadding" class="wrapper__subheader">
        <slot name="subtitle" />
      </div>
    </template>
    <slot v-if="loading || !$slots.loading" />
    <slot v-else name="loading" />
  </component>
</template>

<style lang="scss" scoped>
  .wrapper {
    &.offset {
      margin: 104px 0 56px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    &__subheader {
      color: $main_grey_light;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 64px;
    }
    &__title {
      color: $main_white;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
      &.subtitle {
        font-size: 44px;
      }
    }
    &__link {
      width: fit-content;
      padding: 0;
      &:after {
        content: attr(data-link);
      }
    }
    &__link-mobile {
      display: none;
    }

    @media (max-width: $tablet) {
      &__subheader {
        font-size: 24px;
      }
      &__title {
        font-size: 44px;
        line-height: 50px;
        &.subtitle {
          font-size: 30px;
        }
      }
    }
    @media (max-width: $retina) {
      &.offset {
        margin: 24px 0 0;
      }
      &.hide-title {
        margin: 0;
      }
      &.hide-title & {
        &__title {
          display: none;
        }
      }
      &__title {
        font-size: 28px;
        line-height: 36px;
        &.subtitle {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &__subheader {
        font-size: 16px;
        line-height: 24px;
      }
      &__link {
        &:after {
          content: attr(data-mobile-link);
        }
      }
    }
    @media (max-width: $mobile-large) {
      &__subheader {
        margin-bottom: 24px;
      }
    }
    @media (max-width: $mobile-large) {
      &__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }
    }
  }
</style>
